@import '../../../../scss/theme-bootstrap';

.site-footer-bottom {
  padding: 15px 0 40px;
  background: $color-black;
  font-size: 15px;
  font-family: $body-font;
  color: $color-white;
  border-top: 1px solid $color-gray-dark;
  a {
    color: $color-white;
    margin-bottom: 13px;
  }
  &__main {
    @include breakpoint($medium-up) {
      max-width: $max-width-large;
      padding-top: 25px;
      margin: 0 auto;
      text-align: #{$ldirection};
      display: flex;
    }
  }
  &__trademark {
    color: $color-gray-tooltip;
    padding-top: 54px;
    width: 70%;
    margin: 0 auto;
    @include breakpoint($medium-up) {
      width: 56%;
      padding: 0;
      margin: 0;
      display: inline-block;
      text-align: #{$ldirection};
      vertical-align: top;
    }
  }
  &__links {
    flex-direction: column;
    display: flex;
    align-items: center;
    @include breakpoint($medium-up) {
      width: 30%;
      align-items: flex-start;
    }
  }
  &__menu {
    margin-bottom: 13px;
    .menu__item {
      @include breakpoint($medium-up) {
        display: inline-block;
        width: 48%;
        text-align: #{$ldirection};
        vertical-align: top;
      }
      a,
      a:hover {
        color: $color-gray-tooltip;
      }
    }
  }
  &__accessibility-icon {
    padding-top: 10px;
    @include breakpoint($medium-up) {
      width: 10%;
      display: inline-block;
      vertical-align: top;
      text-align: #{$rdirection};
    }
    img {
      margin: 15px 0 35px 0;
      width: 80px;
      height: 31px;
      @include breakpoint($medium-up) {
        margin: 0;
        height: 20px;
        width: 52px;
      }
    }
  }
  &__sub-footer-image {
    text-align: center;
    width: 100%;
    background-color: $color-white;
    padding-top: 10px;
  }
  &__recycling {
    display: flex;
    align-items: center;
    justify-content: center;
    @include breakpoint($medium-up) {
      justify-content: flex-start;
    }
    &-logo {
      margin-#{$rdirection}: 10px;
      width: 30px;
    }
    &-link {
      a,
      a:hover {
        color: $color-gray-tooltip;
        margin: 0;
      }
    }
  }
}

//adding padding for SPP sticky add to bag.
.page-product .site-footer-bottom__accessibility-icon {
  padding-bottom: 50px;
}

//adding checkout specific styling
body.pg-checkout {
  .site-footer-bottom {
    border: none;
    padding-top: 0;
  }
}
